import React , {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import dermapenImage1 from "../Images/dermapenImage1.jpg";
import dermapenImage2 from "../Images/dermapenImage2.jpg";
import dermapenImage3 from "../Images/dermapenImage3.jpg";
import dermapenImage4 from "../Images/dermapenImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Dermapen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"Dermapen"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Cildinize Gelen Yenilik" />
            </h1>
            <img
              src={dermapenImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Dermapen, ciltteki kolajen üretimini stimule ederek cildinizi
              yenileyen ve gençleştiren bir cilt bakımı yöntemidir.
            </p>
            <p className="mt-3">
              Ciltteki ince çizgileri ve kırışıklıkları azaltır, cilt tonunu ve
              dokusunu iyileştirir.{" "}
            </p>
            <p className="mt-3">
              Dermapen ile cildinizin daha pürüzsüz, aydınlık ve canlı
              görünmesini sağlayabilirsiniz.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Cilt Problemlerine Çözüm" />
            </h1>
            <img
              src={dermapenImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Dermapen, akne izleri, cilt lekeleri, genişlemiş gözenekler ve
              cilt sarkması gibi birçok cilt problemini çözmeye yardımcı olur.
            </p>
            <p className="mt-3">
              Ciltteki yara izlerini ve çatlakları da azaltabilir.{" "}
            </p>
            <p className="mt-3">
              Dermapen ile cildinizin daha sağlıklı ve güzel görünmesini
              sağlayabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Acısız ve Konforlu" />
            </h1>
            <img
              src={dermapenImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">Dermapen, acısız ve konforlu bir işlemdir. </p>
            <p className="mt-3">
              {" "}
              Cildinize anestezik krem uygulanarak işlem sırasında herhangi bir
              ağrı veya rahatsızlık hissetmezsiniz.{" "}
            </p>
            <p className="mt-3">
              Dermapen ile cildinizin tazelendiğini ve canlandığını
              hissedeceksiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Her Cilt Tipine Uygun" />
            </h1>
            <img
              src={dermapenImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Dermapen, her cilt tipine uygulanabilen bir cilt bakımı
              yöntemidir.
            </p>
            <p className="mt-3">
              Kuru, yağlı, karma veya hassas cilt tiplerinde güvenle
              kullanılabilir.{" "}
            </p>
            <p className="mt-3">
              Dermapen ile cildinizin ihtiyacı olan özel bakımı alabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dermapen;
