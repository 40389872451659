import React , {useEffect} from 'react';
import BreadCrumb from './BreadCrumb';
import logo1 from '../Images/logo.png';
import './about.css'
import aboutUs from '../Images/aboutUs.jpg'
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb name="Hakkımızda" image={aboutUs}/>
      <div className="container about-container">
        <div className="row">
          <div className="col-md-12 text-center animate__animated animate__fadeInDown ">
            <img src={logo1} alt="logo" className="img-fluid mb-3" />
          </div>
          <div className="col-md-8 offset-md-2 animate__animated animate__fadeInUp ">
            <p className="lead mt-3 about-text">
              Güneş Özcan Beauty Center, güzelliğin ve kişisel bakımın sadece bir lüks değil, bir ihtiyaç olduğuna inanan ve bu doğrultuda hem erkekler hem de kadınlar için özel olarak tasarlanmış geniş bir yelpazede hizmetler sunan bir güzellik salonudur.
            </p>
            <h2>Vizyonumuz:</h2>
            <p className='about-text'>
              Herkese, güzelliğinin ve kendine güveninin ışığının parlaması için en iyi hizmeti sunmak.
            </p>
            <h2>Misyonumuz:</h2>
            <p className='about-text'>
              Uzman ekibimiz ve son teknoloji ekipmanlarımız ile, müşterilerimizin ihtiyaç ve isteklerine uygun, en kaliteli ve güvenilir hizmetleri sunarak, güzellik ve kişisel bakım alanında lider bir kuruluş olmak.
            </p>
            <h4>Neden Güneş Özcan Beauty Center?</h4>
            <ul className='about-text'>
              <li>Uzman Ekip: Ekibimiz, güzellik ve kişisel bakım alanında uzman ve deneyimli kişilerden oluşmaktadır.</li>
              <li>Son Teknoloji Ekipmanlar: En son teknoloji ekipmanlar ile en iyi sonuçları elde ediyoruz.</li>
              <li>Geniş Hizmet Yelpazesi: Cilt bakımı, lazer epilasyon, bölgesel zayıflama, kalıcı makyaj, hydrafacial, dermapen, G9 Masajı, manikür ve kalıcı oje gibi geniş bir yelpazede hizmetler sunuyoruz.</li>
              <li>Müşteri Memnuniyeti: Müşteri memnuniyeti bizim için en önemli önceliğimizdir.</li>
              <li>Uygun Fiyatlar: Herkese uygun fiyatlarla en iyi hizmeti sunmayı hedefliyoruz.</li>
            </ul>
            <h4>Güneş Özcan Beauty Center'da size sunduğumuz hizmetler:</h4>
            <ul className='about-text'>
              <li>Cilt Bakımı: Cildinizin ihtiyacına özel olarak tasarlanmış cilt bakımı programları ile cildinize ihtiyacı olan özeni ve ilgiyi gösteriyoruz.</li>
              <li>Lazer Epilasyon: İstenmeyen tüylerden kalıcı olarak kurtulmak ve pürüzsüz bir cilde kavuşmak için en son teknoloji lazer epilasyon cihazlarımızı kullanıyoruz.</li>
              <li>Bölgesel Zayıflama: İnatçı yağlardan kurtulmak ve daha fit bir vücuda sahip olmak için modern ve etkili bölgesel zayıflama uygulamaları sunuyoruz.</li>
              <li>Kalıcı Makyaj: Kaş, eyeliner ve dudaklarınız için kalıcı makyaj uygulamaları ile zamandan tasarruf etmenizi ve her zaman kusursuz görünmenizi sağlıyoruz.</li>
              <li>Hydrafacial: Cildinizi derinlemesine temizleyen, nemlendiren ve yenileyen Hydrafacial uygulaması ile cildinize ışıltı katıyoruz.</li>
              <li>Dermapen: Ciltteki ince çizgileri, kırışıklıkları ve akne izlerini azaltmak için yenilikçi Dermapen uygulaması gerçekleştiriyoruz.</li>
              <li>G9 Masajı: Kan dolaşımını artırmak, selülitleri azaltmak ve kas gerginliğini hafifletmek için canlandırıcı G9 Masajı hizmeti veriyoruz.</li>
              <li>Manikür ve Kalıcı Oje: Ellerinizin her zaman bakımlı ve göz alıcı görünmesi için manikür ve kalıcı oje hizmeti veriyoruz.</li>
            </ul>
            <p>Güneş Özcan Beauty Center ile güzelliğinize güneş ışığıyla dokunun!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
