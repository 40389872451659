import React , {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import kirpikImage1 from "../Images/kirpikImage1.jpg";
import kirpikImage2 from "../Images/kirpikImage2.jpg";
import kirpikImage3 from "../Images/kirpikImage3.jpg";
import kirpikImage4 from "../Images/kirpikImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Ipek = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"İpek Kirpik"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Bakışlarınızı Güçlendirin" />
            </h1>
            <img
              src={kirpikImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              İpek kirpikler, bakışlarınızı derinleştirerek daha etkileyici ve
              çekici görünmenizi sağlar.
            </p>
            <p className="mt-3">
              Doğal kirpiklerinizden daha uzun ve hacimli olan ipek kirpikler...{" "}
            </p>
            <p className="mt-3">
              Gözlerinizi ön plana çıkararak bakışlarınıza anlam katar.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Zamandan Tasarruf Edin" />
            </h1>
            <img
              src={kirpikImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              İpek kirpikler, her gün maskara sürme ihtiyacını ortadan kaldırır.
            </p>
            <p className="mt-3">
              Sabah uyandığınızda bile kirpikleriniz uzun ve hacimli görünür,
              zamandan tasarruf edersiniz.
            </p>
            <p className="mt-3">
              Makyaj yapmadan da gözlerinizin güzelliğini vurgulayabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kendinize Güvenin" />
            </h1>
            <img
              src={kirpikImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              İpek kirpikler, özgüveninizi artırmanın en kolay yollarından
              biridir.
            </p>
            <p className="mt-3">
              Daha güzel ve çekici bakışlara sahip olmak, kendinizi daha iyi
              hissetmenizi sağlar.
            </p>
            <p className="mt-3">
              Her ortamda özgüvenle bakışlarınızı ön plana çıkarabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Doğal ve Kalıcı Güzellik" />
            </h1>
            <img
              src={kirpikImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              İpek kirpikler, doğal bir görünüme sahiptir. Uygun şekilde
              uygulandığında, kirpiklerinizin doğal halinden ayırt edilemez.{" "}
            </p>
            <p className="mt-3">
              İpek kirpikler, 4-6 hafta boyunca kalıcılığını korur.
            </p>
            <p className="mt-3">
              Güzelliğinizin tadını uzun süre çıkarabilirsiniz.İpek kirpikler
              ile hayalinizdeki bakışlara kavuşabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ipek;
