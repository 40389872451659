import React from "react";
import errorImage from "../Images/error.png";
import BreadCrumb from "./BreadCrumb";
import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 col-md-6 d-flex align-items-center justify-content-center flex-column">
            <h1>404 - Not Found</h1>
            <p>Böyle bir sayfa bulunmuyor. </p>
            <NavLink to="/" className="btn button btn-lg mt-3">
              Ana Sayfa
            </NavLink>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img src={errorImage} alt="Error 404" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
