import React , {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import lazerImage1 from "../Images/lazerImage1.jpg";
import lazerImage2 from "../Images/lazerImage2.jpg";
import lazerImage3 from "../Images/lazerImage3.jpg";
import lazerImage4 from "../Images/lazerImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Lazer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"Lazer Epilasyon"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text=" Tüylerinize veda edin, pürüzsüz bir cilde kavuşun!" />
            </h1>
            <img
              src={lazerImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kendinizi iyi hissetmek ve özgüveninizi yükseltmek mi
              istiyorsunuz? Lazer epilasyon, güzelliğinize ve bakımınıza
              yaptığınız en değerli yatırımlardan biri olabilir.
            </p>
            <p className="mt-3">
              Pürüzsüz bir cilde sahip olmak, istediğiniz kıyafetleri rahatça
              giymek, özgürce hareket etmek...{" "}
            </p>
            <p className="mt-3">
              Lazer epilasyon size bunları ve daha fazlasını sunuyor. Kendinizi
              şımartın, güzelliğinizi lazer epilasyonla taçlandırın!{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Lazer epilasyonla istenmeyen tüylerden kalıcı olarak kurtulun" />
            </h1>
            <img
              src={lazerImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Lazer epilasyon hayallerinizdeki pürüzsüz cilde giden en güvenilir
              yol. Jiletlerin kısalığına, ağdanın acısına veda edin.
            </p>
            <p className="mt-3">
              Günlerce, haftalarca süren tüy sorununu kökünden çözün. Kendinizi
              her daim özgür ve bakımlı hissetmenin keyfini keşfedin.{" "}
            </p>
            <p className="mt-3">
              Lazer epilasyon, size zaman kazandırırken pürüzsüzlük ve zarafet
              armağan ediyor.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kendinize zaman ayırın, lazer epilasyona yatırım yapın" />
            </h1>
            <img
              src={lazerImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              İnce tüyler mi, kalın tüyler mi? Artık fark etmez! Lazer epilasyon
              her tür tüy tipine etkili bir çözüm sunuyor.
            </p>
            <p className="mt-3">
              Batıklar, kızarıklıklar, sürekli jilet ve ağda derdi... Bu
              sorunlara elveda deyin.{" "}
            </p>
            <p className="mt-3">
              Lazer epilasyon ile istenmeyen tüyleri kalıcı olarak yok edin,
              pürüzsüzlüğün ve özgüvenin tadını çıkarın.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text=" Güzelliğinizi ve özgüveninizi lazer epilasyonla taçlandırın" />
            </h1>
            <img
              src={lazerImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Jilet, ağda derdiyle uğraşmaktan yorulmadınız mı? Zamanınızı ve
              enerjinizi daha güzel şeylere ayırmak istiyorsanız doğru
              yoldasınız.
            </p>
            <p className="mt-3">
              Lazer epilasyon ile haftalık, aylık tüy alma rutinini unutun.
              Kendinize vakit ayırın, istediğiniz hobilere dalın, plajda rahatça
              eğlenin.{" "}
            </p>
            <p className="mt-3">
              Lazer epilasyon size zaman kazandırırken kendinize daha fazla
              zaman kalmasını sağlar.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lazer;
