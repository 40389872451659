import React , {useEffect} from 'react';
import { useForm, ValidationError } from '@formspree/react';
import BreadCrumb from '../Home/BreadCrumb';
import { Row, Col } from 'react-bootstrap';
import contactImage from '../Images/contact.jpg'
function ContactForm() {
  const [state, handleSubmit] = useForm("xqkrqooz");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (state.succeeded) {
    return(
      <div className='container'>
        <p className="alert alert-success mb-10">Mailinizi Aldık ! Size kısa sürede döneceğiz.</p>;
      </div>
      ) 
  }

  return (
    <div>
      <Row className='col-12'>
        <BreadCrumb name={"Bize Ulaşın"}  image={contactImage}/>
      </Row>
      <form onSubmit={handleSubmit} className="container mt-5 mb-5">
        <Row className="mb-3">
          <Col md={6} className="mb-md-0 mb-3">
            <label htmlFor="firstName" className="form-label">Adınız</label>
            <input
              id="firstName"
              type="text"
              name="firstName"
              className="form-control"
              required
            />
            <ValidationError
              prefix="First Name"
              field="firstName"
              errors={state.errors}
              className="text-danger"
            />
          </Col>
          <Col md={6}>
            <label htmlFor="lastName" className="form-label">Soyadınız</label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              className="form-control"
              required
            />
            <ValidationError
              prefix="Last Name"
              field="lastName"
              errors={state.errors}
              className="text-danger"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6} className="mb-md-0 mb-3">
            <label htmlFor="email" className="form-label">Email Adresiniz</label>
            <input
              id="email"
              type="email"
              name="email"
              className="form-control"
              required
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
              className="text-danger"
            />
          </Col>
          <Col md={6}>
            <label htmlFor="subject" className="form-label">Konu</label>
            <input
              id="subject"
              type="text"
              name="subject"
              className="form-control"
              required
            />
          </Col>
        </Row>
        <div className="mb-3">
          <label htmlFor="message" className="form-label">Mesajınız</label>
          <textarea
            id="message"
            name="message"
            className="form-control"
            required
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
            className="text-danger"
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={state.submitting}>
        Gönder
        </button>
      </form>
    </div>
  );
}

function App() {
  return (
    <div >
      <ContactForm />
    </div>
  );
}

export default App;
