import React, { useEffect } from "react";
import "./App.css";
import {
  Routes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Home from "./Components/Home/Home.jsx";
import Footer from "./Components/Home/Footer.jsx";
import BottomFooter from "./Components/Home/BottomFooter.jsx";
import BackToTop from "./Components/Home/BackToTop.jsx";
import NavBar from "./Components/Home/NavBar.jsx";
import ContactForm from "./Components/Emails/ContactForm.jsx";
import StickyFooter from "./Components/Home/StickyFooter.jsx";
import Lazer from "./Components/Servicess/Lazer.jsx";
import "animate.css";
import Cilt from "./Components/Servicess/Cilt.jsx";
import Kalici from "./Components/Servicess/Kalici.jsx";
import Altin from "./Components/Servicess/Altin.jsx";
import Hydrafacial from "./Components/Servicess/Hydrafacial.jsx";
import Ipek from "./Components/Servicess/Ipek.jsx";
import Dermapen from "./Components/Servicess/Dermapen.jsx";
import Bolgesel from "./Components/Servicess/Bolgesel.jsx";
import Masaji from "./Components/Servicess/Masaji.jsx";
import Manikur from "./Components/Servicess/Manikur.jsx";
import KaliciOje from "./Components/Servicess/KaliciOje.jsx";
import Discount from "./Components/Servicess/Discount.jsx";
import NotFound from "./Components/Home/NotFound.jsx";
import BolgeselMen from "./Components/MaleServices/BolgeselMen.jsx";
import CiltMen from "./Components/MaleServices/CiltMen.jsx";
import DermapenMen from "./Components/MaleServices/DermapenMen.jsx";
import HydrafacialMen from "./Components/MaleServices/HydrafacialMen.jsx";
import LazerMen from "./Components/MaleServices/LazerMen.jsx";
import ContactPage from "./Components/Home/ContactPage.jsx";
import Kirpik from "./Components/Servicess/Kirpik.jsx";
import About from "./Components/Home/About.jsx";
import MasajiMen from "./Components/MaleServices/MasajiMen.jsx";
import Videos from "./Components/Home/Videos.jsx";
import { Helmet } from "react-helmet";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const location = "Merkez Mah. Ali Çavuş Sok. Mehmet Atar İşhanı 5.Kat";
  return (
    <Router className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          property="og:description"
          content={`Güneş Özcan Güzellik Merkezi, lazer epilasyon, cilt bakımı, kalıcı makyaj ve daha fazlası gibi geniş bir güzellik hizmetleri yelpazesi sunmaktadır. ${location} bölgesinde kaliteli güzellik tedavileri için bizi ziyaret edin.`}
        />

        <meta
          name="keywords"
          content="lazer epilasyon, Cilt Bakımı , kalıcı makyaj , Altın Oran Kaş Alımı , Hydrafacial , İpek Kirpik , Dermapen  , Bölgesel Zayıflama , G9 Masajı , Manikür ,Kalıcı Oje "
        />
      </Helmet>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactForm" element={<ContactForm />} />
        <Route path="/LazerEpilasyon" element={<Lazer />} />
        <Route path="/CiltBakımı" element={<Cilt />} />
        <Route path="/KalıcıMakyaj" element={<Kalici />} />
        <Route path="/AltınOranKaşAlımı" element={<Altin />} />
        <Route path="/Hydrafacial" element={<Hydrafacial />} />
        <Route path="/İpekKirpik" element={<Ipek />} />
        <Route path="/Dermapen" element={<Dermapen />} />
        <Route path="/BölgeselZayıflama" element={<Bolgesel />} />
        <Route path="/G5Masajı" element={<Masaji />} />
        <Route path="/Manikür" element={<Manikur />} />
        <Route path="/KalıcıOje" element={<KaliciOje />} />
        <Route path="/BölgeselZayıflaman" element={<BolgeselMen />} />
        <Route path="/CiltBakımın" element={<CiltMen />} />
        <Route path="/Dermapenn" element={<DermapenMen />} />
        <Route path="/Hydrafacialn" element={<HydrafacialMen />} />
        <Route path="/LazerEpilasyonn" element={<LazerMen />} />
        <Route path="/KirpikLifting" element={<Kirpik />} />
        <Route path="/G5Masajın" element={<MasajiMen />} />
        <Route path="/Hakkımızda" element={<About />} />
        <Route path="/Galeri" element={<Videos />} />
        <Route path="/İletişim" element={<ContactPage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <StickyFooter />
      <Footer />
      <BottomFooter />
      <BackToTop />
    </Router>
  );
}

export default App;
