import React from "react";
import "./footer.css";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <div className="container-fluid footer py-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item">
              <h4 className="mb-4 text-white">Hizmet Anlayışımız</h4>
              <p className="text-white">
                Uzman ekibimiz ve son teknoloji ekipmanlarımız ile,
                müşterilerimizin ihtiyaç ve isteklerine uygun, en kaliteli ve
                güvenilir hizmetleri sunarak, güzellik ve kişisel bakım alanında
                lider bir kuruluş olmak.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-white">Hizmetlerimiz</h4>

              <div className="row">
                <div className="col-6">
                  <NavLink to="/LazerEpilasyon" className="nav-link d-block ">
                    Lazer{" "}
                  </NavLink>
                  <NavLink to="/CiltBakımı" className="nav-link d-block ">
                    Cilt Bakımı
                  </NavLink>
                  <NavLink to="/KalıcıMakyaj" className="nav-link d-block ">
                    Kalıcı Makyaj
                  </NavLink>
                  <NavLink
                    to="/AltınOranKaşAlımı"
                    className="nav-link d-block "
                  >
                    Altın Oran Kaş Alımı
                  </NavLink>
                  <NavLink to="/Hydrafacial" className="nav-link d-block ">
                    Hydrafacial
                  </NavLink>
                  <NavLink to="/İpekKirpik" className="nav-link d-block ">
                    İpek Kirpik
                  </NavLink>
                </div>
                <div className="col-6">
                  <NavLink to="/Dermapen" className="nav-link d-block ">
                    Dermapen
                  </NavLink>
                  <NavLink
                    to="/BölgeselZayıflama"
                    className="nav-link d-block "
                  >
                    Bölgesel Zayıflama
                  </NavLink>
                  <NavLink to="/G5Masajı" className="nav-link d-block ">
                    G9 Masajı
                  </NavLink>
                  <NavLink to="/Manikür" className="nav-link d-block ">
                    Manikür
                  </NavLink>
                  <NavLink to="/KalıcıOje" className="nav-link d-block ">
                    Kalıcı Oje
                  </NavLink>
                  <NavLink to="/KirpikLifting" className="nav-link d-block ">
                    Kirpik Lifting
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-white">Çalışma Saatlerimiz</h4>
              <p className="text-warning mb-0">
                Hafta içi: <span className="text-white"> 09:00 – 22:00 </span>
              </p>
              <p className="text-warning mb-0">
                Cumartesi: <span className="text-white"> 09:00 – 22:00 </span>
              </p>
              <p className="text-warning mb-0">
                Pazar: <span className="text-white"> Kapalı </span>
              </p>
              <h4 className="my-4 text-white">Adres</h4>
              <p className="mb-0">
                Alparslan Türkeş bulvarı NO:226/03 ERDEMLİ/MERSİN
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-white">Bizi Takip Edin</h4>
              <NavLink
                to="https://www.facebook.com/profile.php?id=61556518408100&mibextid=ZbWKwL"
                target="_blank"
              >
                {" "}
                Facebook
              </NavLink>
              <NavLink
                to="https://www.instagram.com/gunesozcan_guzellikatolyesi/"
                target="_blank"
              >
                {" "}
                Instagram
              </NavLink>
              <h4 className="my-4 text-white">İletişim</h4>
              <p className="mb-0">
                <i className="fas fa-envelope text-secondary me-2"></i>
                gunesozcan880@gmail.com
              </p>
              <p className="mb-0">
                <i className="fas fa-phone text-secondary me-2"></i>0545 593 13
                33
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
