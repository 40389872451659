import React , {useEffect} from "react";
import cilt1 from "../Images/cilt1.jpg";
import cilt2 from "../Images/cilt2.jpg";
import cilt3 from "../Images/cilt3.jpg";
import cilt4 from "../Images/cilt4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "../Servicess/lazer.css";
import AnimatedText from "../Servicess/AnimatedText";
import { IoMdMale } from "react-icons/io";
import breadmale from "../Images/breadmale.jpg";

const CiltMen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb name={"Cilt Bakımı"} icon={<IoMdMale />} image={breadmale} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Daha Sağlıklı ve Bakımlı Cilt" />
            </h1>
            <img
              src={cilt1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Cilt bakımı, erkeklerin de ciltlerinin daha sağlıklı ve bakımlı
              görünmesini sağlar.
            </p>
            <p className="mt-3">
              Düzenli cilt bakımı ile ciltteki siyah noktalar, sivilceler ve
              kırışıklıklar azalır.{" "}
            </p>
            <p className="mt-3">
              Cilt daha pürüzsüz, parlak ve canlı bir görünüm kazanır.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Artan Özgüven" />
            </h1>
            <img
              src={cilt2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Bakımlı bir cilt, erkeklerin de daha özgüvenli hissetmesini
              sağlar.
            </p>
            <p className="mt-3">
              Kendilerini daha çekici ve karizmatik hissederler.{" "}
            </p>
            <p className="mt-3">
              Cilt problemleri ile uğraşmak yerine, özgüvenleri artar ve
              kendilerine daha çok zaman ayırabilirler.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text=" Daha Etkili Tıraş" />
            </h1>
            <img
              src={cilt3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Düzenli cilt bakımı, tıraşın daha kolay ve pürüzsüz olmasını
              sağlar.
            </p>
            <p className="mt-3">
              Tıraş sonrası tahriş ve kızarıklıklar azalır.{" "}
            </p>
            <p className="mt-3">Cilt daha yumuşak ve rahat hisseder. </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Daha Genç Görünüm" />
            </h1>
            <img
              src={cilt4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Cilt bakımı, yaşlanma belirtilerini geciktirir ve daha genç
              görünmenizi sağlar.
            </p>
            <p className="mt-3">Kırışıklıklar ve ince çizgiler azalır. </p>
            <p className="mt-3">
              Cilt daha sıkı ve elastik bir hale gelir.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CiltMen;
