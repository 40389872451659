import React, { useState, useEffect } from 'react';
import { useTrail, animated } from 'react-spring';
import './animate.css'
const AnimateOnce = ({ text }) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setShowText(true); 
  }, []); 

  const chars = text.split('');
  const config = { mass: 1, tension: 400, friction: 30 };

  const trail = useTrail(chars.length, {
    opacity: showText ? 1 : 0,
    transform: showText ? 'translateY(0px)' : 'translateY(50px)',
    config,
    from: { opacity: 0, transform: 'translateY(50px)' },
  });

  return (
    <span className=" mb-4 head animate-once">
      {trail.map((props, index) => (
        <animated.span key={index} style={props}>
          {chars[index]}
        </animated.span>
      ))}
    </span>
  );
};

export default AnimateOnce;
