import React , {useEffect} from "react";
import derma1 from "../Images/derma1.jpeg";
import derma2 from "../Images/derma2.jpg";
import derma3 from "../Images/derma3.jpg";
import derma4 from "../Images/derma4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "../Servicess/lazer.css";
import AnimatedText from "../Servicess/AnimatedText";
import { IoMdMale } from "react-icons/io";
import breadmale from "../Images/breadmale.jpg";

const DermapenMen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb name={"Dermapen"} icon={<IoMdMale />} image={breadmale} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kolajen Üretimini Artırır" />
            </h1>
            <img
              src={derma1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Dermapen, ciltte mikro iğnelemeler yaparak kolajen üretimini
              artırır.
            </p>
            <p className="mt-3">
              Bu sayede cilt daha sıkı ve elastik hale gelir.{" "}
            </p>
            <p className="mt-3">Kırışıklıklar ve ince çizgiler azalır. </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Akne İzlerini Azaltır" />
            </h1>
            <img
              src={derma2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Dermapen, akne izlerinin görünümünü hafifletmeye yardımcı olur.
            </p>
            <p className="mt-3">Cilt dokusunu düzeltir ve pürüzsüzleştirir. </p>
            <p className="mt-3">Daha eşit bir cilt tonu sağlar. </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Leke ve Pigmentasyonu Azaltır" />
            </h1>
            <img
              src={derma3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Dermapen, güneş lekeleri ve yaşlılık lekeleri gibi cilt lekelerini
              azaltmaya yardımcı olur.
            </p>
            <p className="mt-3">Cilt tonunu eşitler ve aydınlatır. </p>
            <p className="mt-3">
              Daha parlak ve canlı bir cilt görünümü sağlar.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Cilt Bakım Ürünlerinin Emilimini Artırır" />
            </h1>
            <img
              src={derma4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Dermapen, ciltteki mikro kanallar sayesinde cilt bakım ürünlerinin
              daha derinlere nüfuz etmesini sağlar.
            </p>
            <p className="mt-3">
              Ürünlerin etkisini artırır ve daha hızlı sonuç almanızı sağlar.{" "}
            </p>
            <p className="mt-3">
              Dermapen, ciltteki yara izlerini ve çatlakları da azaltmaya
              yardımcı olabilir.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DermapenMen;
