import React , {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import altinImage1 from "../Images/altinImage1.jpg";
import altinImage2 from "../Images/altinImage2.jpg";
import altinImage3 from "../Images/altinImage3.jpg";
import altinImage4 from "../Images/altinImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Altin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb
        image={car1}
        name={"Altın Oran Kaş Alımı"}
        icon={<IoMdFemale />}
      />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Mükemmel Simetriye Ulaşın" />
            </h1>
            <img
              src={altinImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Altın oran kaş alımı, yüzünüze en uygun kaş şeklini oluşturmak
              için kullanılan bir yöntemdir.
            </p>
            <p className="mt-3">
              Bu yöntem, kaşlarınızın doğal güzelliğini ortaya çıkararak yüz
              hatlarınızın simetrisini ve uyumunu sağlar. merninisti licere{" "}
            </p>
            <p className="mt-3">
              Altın oran kaş alımı ile daha çekici ve etkileyici bir görünüme
              kavuşabilirsiniz.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Yüzünüze Işık Katın" />
            </h1>
            <img
              src={altinImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Doğru kaş şekli, yüzünüzün en güzel özelliklerini vurgulayarak
              daha aydınlık ve canlı görünmesini sağlar.
            </p>
            <p className="mt-3">
              Altın oran kaş alımı ile kaşlarınızın doğal hatlarını takip ederek
              yüzünüze en uygun kaş şeklini oluştururuz.{" "}
            </p>
            <p className="mt-3">
              Bu sayede yüzünüzün daha simetrik ve orantılı görünmesini
              sağlarken, gözlerinizi ön plana çıkararak bakışlarınıza derinlik
              katarız.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kendinize Güveninizi Artırın" />
            </h1>
            <img
              src={altinImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Güzel ve simetrik kaşlara sahip olmak, özgüveninizi artırmanın en
              etkili yollarından biridir.{" "}
            </p>
            <p className="mt-3">
              Altın oran kaş alımı ile yüzünüze en uygun kaş şeklini oluşturarak
              kendinizi daha güzel ve çekici hissedebilirsiniz.{" "}
            </p>
            <p className="mt-3">
              Her ortamda özgüvenle bakışlarınızı ön plana çıkarabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Uzman Dokunuşla Mükemmellik" />
            </h1>
            <img
              src={altinImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Altın oran kaş alımı, uzmanlık ve deneyim gerektiren bir işlemdir.
              Kaşlarınızın doğal güzelliğini ortaya çıkarmak ve yüzünüze en
              uygun kaş şeklini oluşturmak için alanında uzman ekibimizle
              hizmetinizdeyiz.
            </p>
            <p className="mt-3">
              Yüz hatlarınızı analiz ederek altın oran hesaplamalarına göre
              kaşlarınızı şekillendiriyoruz.{" "}
            </p>
            <p className="mt-3">
              Bu sayede en ideal kaşlara sahip olarak güzelliğinizi
              tamamlıyoruz.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Altin;
