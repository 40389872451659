import React, { useEffect } from "react";
import "./contact.css";
import { MdLocationOn } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMailUnread } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import gps from "../Images/gps.jpg";
const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb name={"İletişim"} image={gps} />
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row g-4 align-items-center">
            <div className="col-12">
              <div className="row g-4">
                <div className="col-lg-4">
                  <div
                    className="d-inline-flex bg-light w-100 border border-warning contact-infos  rounded"
                    data-aos="fade-up"
                  >
                    <NavLink
                      className="me-4 mt-2 nav-hover"
                      to="https://maps.app.goo.gl/2Bpb3TVyUbdkwDVh6"
                      target="_blank"
                    >
                      <MdLocationOn size={30} color={"#001f3f"} />
                    </NavLink>
                    <div>
                      <h4>Adres</h4>
                      <p className="mb-0">
                        Alparslan Türkeş bulvarı NO:226/03 ERDEMLİ/MERSİN
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-inline-flex bg-light w-100 border border-warning contact-infoss  rounded"
                    data-aos="fade-up"
                  >
                    <NavLink
                      className="me-4 mt-2 nav-hover"
                      to={"/contactForm"}
                      target="_blank"
                    >
                      <IoMailUnread size={30} color={`#001f3f`} />
                    </NavLink>
                    <div>
                      <h4>Bize Mail Atın</h4>
                      <p className="mb-0">gunesozcan880@gmail.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-inline-flex bg-light w-100 border border-warning contact-infoss rounded"
                    data-aos="fade-up"
                  >
                    <NavLink
                      className="me-4 mt-2 nav-hover"
                      to={"tel:+905455931333"}
                      target="_blank"
                    >
                      <FaPhoneAlt size={30} color={`#001f3f`} />
                    </NavLink>
                    <div>
                      <h4>Telefon</h4>
                      <p className="mb-0">0545 593 13 33</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="rounded">
                {/* <iframe
                className="rounded-top w-100"
                style={{ height: "450px", marginBottom: "-6px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6405.615794913543!2d34.308026725892766!3d36.60693546732087!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d86332edc3f39f%3A0x6352e5e489ca216!2zR8O8bmXFnyDDlnpjYW4gQmVhdXR5IENlbnRlcg!5e0!3m2!1str!2seg!4v1708285920977!5m2!1str!2seg"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe> */}
                <iframe
                  className="rounded-top w-100"
                  style={{ height: "450px", marginBottom: "-6px" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3202.708320999825!2d34.313229076597864!3d36.60933337843436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d8633b149f3edd%3A0x1d1b32f4d94ee70!2sAlata%2C%20Alparslan%20T%C3%BCrke%C5%9F%20Blv.%20No%3A226%20D%3A03%2C%2033730%20Erdemli%2FMersin!5e0!3m2!1str!2str!4v1722540224313!5m2!1str!2str"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className=" text-center p-4 rounded-bottom contact-foot">
                <h4 className="text-white fw-bold">Bizi takip edin</h4>
                <div className="d-flex align-items-center justify-content-center">
                  <NavLink
                    to="https://www.instagram.com/gunesozcan_guzellikatolyesi/"
                    target="_blank"
                    className="btn btn-light btn-light-outline-0 btn-square rounded me-3"
                  >
                    <FaInstagram size={"30"} color={"#9D8221"} />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactPage;
