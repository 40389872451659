import React, {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import g5Image1 from "../Images/g5Image1.jpg";
import g5Image2 from "../Images/g5Image2.jpg";
import g5Image3 from "../Images/g5Image3.jpg";
import g5Image4 from "../Images/g5Image4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Masaji = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"G9 Masajı"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Daha Güçlü" />
            </h1>
            <img
              src={g5Image1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
            G9, G5'e göre 2 kat motor gücüne sahiptir. Bu da G9'un kasları daha fazla çalıştırarak daha etkili bir şekilde kas gelişimi ve yağ yakımı sağlamasını mümkün kılar.
            </p>
            <p className="mt-3">
              Vücudunuzdaki stresi ve yorgunluğu atarak rahatlamanıza ve
              yenilenmenize yardımcı olur.{" "}
            </p>
            <p className="mt-3">
              Kan dolaşımını hızlandırır,metabolizmayı ve yağ yakımını artırır{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Selülitlere Veda Edin" />
            </h1>
            <img
              src={g5Image2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              G9, G5'e göre daha güvenli bir işlemdir. G5, ciltte tahrişe ve morarmaya neden olabilirken, G9 bu tür riskler taşımaz.
            </p>
            <p className="mt-3">
            G9, G5'te bulunmayan radyo frekans ve vakum başlığı da dahil olmak üzere birden fazla teknolojiyi kullanır. Radyo frekans ve vakum başlığı zayıflama sağlar, masaj başlığı ise selülitlerle savaşır.{" "}
            </p>
            <p className="mt-3">
              G9 MASAJ, tüm vücuda uygulanabilen bir masaj yöntemidir. Sırt,
              bacak, kol, karın gibi bölgelere özel olarak uygulanabilir.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Vücudunuzu Şekillendirin" />
            </h1>
            <img
              src={g5Image3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              G9 MASAJ, kan dolaşımını hızlandırarak metabolizmanızı ve yağ
              yakımını artırır.
            </p>
            <p className="mt-3">
              Düzenli G9 MASAJ uygulaması ile daha fit ve sıkı bir vücuda sahip
              olmanız mümkündür.{" "}
            </p>
            <p className="mt-3">
            G9 seansları G5 seanslarına göre daha kısadır. Bu da G9'u zaman açısından daha avantajlı hale getirir.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Cildinizin Canlansın" />
            </h1>
            <img
              src={g5Image4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              G9 MASAJ, cildinizin kan dolaşımını artırarak daha canlı ve parlak
              görünmesine yardımcı olur.
            </p>
            <p className="mt-3">
              Cilt hücrelerinin yenilenmesini stimule ederek cildinizin daha
              sağlıklı ve pürüzsüz bir görünüme kavuşmasını sağlar.{" "}
            </p>
            <p className="mt-3">
              G9 Masajı güzellik merkezimizde yaptırabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Masaji;
