import React from "react";
import "./caro.css";
import car1 from "../Images/car1.jpg";
import car2 from "../Images/car2.jpg";
import car3 from "../Images/car3.jpg";
import { NavLink } from "react-router-dom";
import AnimateOnce from "./AnimateOnce";

const Carousel = () => {
  return (
    <div className="container-fluid carousel-header px-0">
      <div id="carouselId" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
        <ul className="carousel-indicators">
          <li
            data-bs-target="#carouselId"
            data-bs-slide-to="0"
            className="active"
          ></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
        </ul>
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item active">
            <img src={car1} className="img-fluid" alt="Image" />
            <div className="carousel-caption">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <h5 className="car-text text-uppercase mb-3">
                  <AnimateOnce text="Güneş Özcan Beauty Center ile Güzelliğiniz güneş gibi parlasın!" />
                </h5>
                <h1 className="text-capitalize text-light mb-3 animate__animated animate__backInUp">
                  G9 Masajı
                </h1>
                <p className="mx-md-5 fs-6 px-4 mb-5 text-light">
                G9, G5'e göre daha güvenli bir işlemdir. G5, ciltte tahrişe ve morarmaya neden olabilirken, G9 bu tür riskler taşımaz.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <NavLink
                    className="btn btn-light btn-light-outline-0 rounded-pill py-3 px-5 me-4 contact-now"
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=05455931333&text=Merhaba"
                  >
                    Bizi Arayın
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={car2} className="img-fluid" alt="Image" />
            <div className="carousel-caption">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <p
                  className="car-text  text-uppercase mb-3"
                  style={{ letterSpacing: "3px" }}
                >
                  <AnimateOnce text="Güneş Özcan Beauty Center ile güzelliğiniz güneş gibi parlasın!" />
                </p>
                <h1 className=" text-capitalize text-light mb-3 animate__animated animate__backInUp">
                  Lazer Epilasyon
                </h1>
                <p className="mx-md-5 fs-6 px-5 mb-5 text-light">
                  İstenmeyen tüylerden kalıcı olarak kurtulmak ve pürüzsüz bir
                  cilde kavuşmak için en son teknoloji lazer epilasyon
                  cihazlarımızı kullanıyoruz
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <NavLink
                    className="btn btn-light btn-light-outline-0 rounded-pill py-3 px-5 me-4 contact-now"
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=05455931333&text=Merhaba"
                  >
                    Bizi Arayın
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={car3} className="img-fluid" alt="Image" />
            <div className="carousel-caption">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <h5
                  className="car-text  text-uppercase mb-3"
                  style={{ letterSpacing: "3px" }}
                >
                  <AnimateOnce text="Güneş Özcan Beauty Center ile güzelliğiniz güneş gibi parlasın!" />
                </h5>
                <h1 className=" text-capitalize text-light animate__animated animate__backInUp">
                  Kalıcı Makyaj
                </h1>
                <p className="mx-md-5 fs-6 px-5 mb-5 text-light">
                  Kaş, eyeliner ve dudaklarınız için kalıcı makyaj uygulamaları
                  ile zamandan tasarruf etmenizi ve her zaman kusursuz
                  görünmenizi sağlıyoruz.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <NavLink
                    className="btn btn-light btn-light-outline-0 rounded-pill py-3 px-5 me-4 contact-now"
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=05455931333&text=Merhaba"
                  >
                    Bizi Arayın
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
