import React from "react";
import "./services.css";
import logo from "../Images/logo.png";
import { NavLink } from "react-router-dom";
import kalici from "../Images/kalici-makyaj.jpg";
import lazeralt from "../Images/lazeralt.jpg";
import ciltbakim from "../Images/ciltbakim.jpg";
import altinoran from "../Images/altinoran.jpg";
import hydrafacial from "../Images/hydrafacial.jpg";
import ipekkirpik from "../Images/ipekkirpik.jpg";
import dermapen from "../Images/dermapen.jpg";
import bolgesel2 from "../Images/bolgesel2.jpg";
import kalicioje from "../Images/ojekalici.jpg";
import manikur from "../Images/manikur.jpg";
import g5masaj from "../Images/g5masaj.jpg";
import lifting from "../Images/lifting.jpg";
const Services = () => {
  return (
    <div className="container-fluid services py-5">
      <div className="container py-5">
        <div className="mx-auto text-center mb-5">
          <p className="fs-4 text-uppercase text-center service">
            Hizmetlerimiz
          </p>
          <h1 className="display-3">Our Beauty Services</h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-end  rounded p-4 ">
              <div className="row align-items-center" data-aos="fade-up">
                <div className="col-8">
                  <div className="services-content text-end">
                    <h3 className="services-head">Lazer Epilasyon</h3>
                    <p className="services-head">
                      İstenmeyen tüylerden kalıcı olarak kurtulmak ve pürüzsüz
                      bir cilde kavuşmak için en son teknoloji lazer epilasyon
                      cihazlarımızı kullanıyoruz.
                    </p>
                    <NavLink
                      to="LazerEpilasyon"
                      className="btn btn-light  rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to={"/LazerEpilasyon"}>
                      <img
                        src={lazeralt}
                        className="img-fluid rounded"
                        alt=""
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-start  rounded p-4">
              <div className="row align-items-center" data-aos="fade-down">
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to={"/CiltBakımı"}>
                      <img
                        src={ciltbakim}
                        className="img-fluid rounded"
                        alt=""
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col-8">
                  <div className="services-content text-start">
                    <h3 className="services-head">Cilt Bakımı</h3>
                    <p className="services-head">
                      Cildinizin ihtiyacına özel olarak tasarlanmış cilt bakımı
                      programları ile cildinize ihtiyacı olan özeni ve ilgiyi
                      gösteriyoruz.
                    </p>
                    <NavLink
                      to="CiltBakımı"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-end  rounded p-4">
              <div className="row align-items-center">
                <div className="col-8">
                  <div className="services-content text-end" data-aos="fade-up">
                    <h3 className="services-head">Kalıcı Makyaj</h3>
                    <p className="services-head">
                      Kaş, eyeliner ve dudaklarınız için kalıcı makyaj
                      uygulamaları ile zamandan tasarruf etmenizi ve her zaman
                      kusursuz görünmenizi sağlıyoruz.
                    </p>
                    <NavLink
                      to="KalıcıMakyaj"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to={"KalıcıMakyaj"}>
                      <img src={kalici} className="img-fluid rounded" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-start  rounded p-4">
              <div className="row align-items-center" data-aos="fade-down">
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to={"/AltınOranKaşAlımı"}>
                      <img
                        src={altinoran}
                        className="img-fluid rounded"
                        alt=""
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col-8">
                  <div className="services-content text-start">
                    <h3 className="services-head">Altın Oran Kaş Alımı</h3>
                    <p className="services-head">
                      Yüzünüze en uygun kaş şeklini belirlemek için altın oran
                      kaş alma tekniğini kullanıyoruz.
                    </p>
                    <NavLink
                      to="AltınOranKaşAlımı"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-end  rounded p-4">
              <div className="row align-items-center" data-aos="fade-up">
                <div className="col-8">
                  <div className="services-content text-end">
                    <h3 className="services-head">Hydrafacial</h3>
                    <p className="services-head">
                      Cildinizi derinlemesine temizleyen, nemlendiren ve
                      yenileyen Hydrafacial uygulaması ile cildinize ışıltı
                      katıyoruz
                    </p>
                    <NavLink
                      to="Hydrafacial"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to={"/Hydrafacial"}>
                      <img
                        src={hydrafacial}
                        className="img-fluid rounded"
                        alt=""
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-start  rounded p-4">
              <div className="row align-items-center" data-aos="fade-down">
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to="/İpekKirpik">
                      <img
                        src={ipekkirpik}
                        className="img-fluid rounded"
                        alt=""
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col-8">
                  <div className="services-content text-start">
                    <h3 className="services-head">İpek Kirpik</h3>
                    <p className="services-head">
                      Daha uzun ve hacimli kirpiklere sahip olmak için ipek
                      kirpik uygulaması gerçekleştiriyoruz.
                    </p>
                    <NavLink
                      to="/İpekKirpik"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-end  rounded p-4">
              <div className="row align-items-center" data-aos="fade-up">
                <div className="col-8">
                  <div className="services-content text-end">
                    <h3 className="services-head">Dermapen</h3>
                    <p className="services-head">
                      Ciltteki ince çizgileri, kırışıklıkları ve akne izlerini
                      azaltmak için Dermapen uygulaması gerçekleştiriyoruz.
                    </p>
                    <NavLink
                      to="Dermapen"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to="/Dermapen">
                      <img
                        src={dermapen}
                        className="img-fluid rounded"
                        alt=""
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-start  rounded p-4">
              <div className="row align-items-center" data-aos="fade-down">
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to="BölgeselZayıflama">
                      <img
                        src={bolgesel2}
                        className="img-fluid rounded"
                        alt=""
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col-8">
                  <div className="services-content text-start">
                    <h3 className="services-head">Bölgesel Zayıflama</h3>
                    <p className="services-head">
                      İnatçı yağlardan kurtulmak ve daha fit bir vücuda sahip
                      olmak için bölgesel zayıflama uygulamaları sunuyoruz.
                    </p>
                    <NavLink
                      to="BölgeselZayıflama"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-end  rounded p-4">
              <div className="row align-items-center" data-aos="fade-up">
                <div className="col-8">
                  <div className="services-content text-end">
                    <h3 className="services-head">G9 Masajı</h3>
                    <p className="services-head">
                      Kan dolaşımını artırmak, selülitleri azaltmak ve kas
                      gerginliğini hafifletmek için G9 Masajı hizmeti veriyoruz.
                    </p>
                    <NavLink
                      to="G5Masajı"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to="/G5Masajı">
                      <img src={g5masaj} className="img-fluid rounded" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-start  rounded p-4">
              <div className="row align-items-center" data-aos="fade-down">
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to="/Manikür">
                      <img src={manikur} className="img-fluid rounded" alt="" />
                    </NavLink>
                  </div>
                </div>
                <div className="col-8">
                  <div className="services-content text-start">
                    <h3 className="services-head">Manikür</h3>
                    <p className="services-head">
                      Ellerinizin her zaman bakımlı ve güzel görünmesi için
                      manikür ve kalıcı oje hizmeti veriyoruz.
                    </p>
                    <NavLink
                      to="Manikür"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-end  rounded p-4">
              <div className="row align-items-center" data-aos="fade-up">
                <div className="col-8">
                  <div className="services-content text-end">
                    <h3 className="services-head">Kalıcı Oje</h3>
                    <p className="services-head">
                      ellerinizin her zaman bakımlı ve güzel görünmesi için
                      kalıcı oje hizmeti sunuyoruz.
                    </p>
                    <NavLink
                      to="KalıcıOje"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to={"/KalıcıOje"}>
                      <img
                        src={kalicioje}
                        className="img-fluid rounded"
                        alt=""
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="services-item bg-light border-4 border-start  rounded p-4">
              <div className="row align-items-center" data-aos="fade-down">
                <div className="col-4">
                  <div className="services-img d-flex align-items-center justify-content-center rounded">
                    <NavLink to={"/KirpikLifting"}>
                      <img src={lifting} className="img-fluid rounded" alt="" />
                    </NavLink>
                  </div>
                </div>
                <div className="col-8">
                  <div className="services-content text-start">
                    <h3 className="services-head">Kirpik Lifting</h3>
                    <p className="services-head">
                      Kirpiklerinizi daha uzun ve hacimli göstermek için kirpik
                      lifting uygulaması gerçekleştiriyoruz.
                    </p>
                    <NavLink
                      to="/KirpikLifting"
                      className="btn btn-light btn-outline-warning rounded-pill py-2 px-4 detaylar"
                    >
                      Detaylar
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12">
            <div className="services-btn text-center">
              <NavLink
                to="#"
                className="btn btn-light btn-outline-light rounded-pill py-3 px-5"
              >
                More
              </NavLink>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Services;
