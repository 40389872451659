import React, {useEffect} from "react";
import hydra1 from "../Images/hydra1.jpg";
import hydra2 from "../Images/hydra2.png";
import hydra3 from "../Images/hydra3.jpg";
import hydra4 from "../Images/hydra4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "../Servicess/lazer.css";
import AnimatedText from "../Servicess/AnimatedText";
import { IoMdMale } from "react-icons/io";
import breadmale from "../Images/breadmale.jpg";

const HydrafacialMen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb name={"Hydrafacial"} icon={<IoMdMale />} image={breadmale} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Derinlemesine Temizlik" />
            </h1>
            <img
              src={hydra1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Hydrafacial, ciltteki kir, yağ ve makyaj kalıntılarını
              derinlemesine temizler.
            </p>
            <p className="mt-3">
              Gözeneklerin tıkanmasını önler ve siyah nokta oluşumunu engeller.{" "}
            </p>
            <p className="mt-3">
              Daha pürüzsüz ve parlak bir cilt görünümü sağlar.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Cilt Yenilenmesi" />
            </h1>
            <img
              src={hydra2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Hydrafacial, ciltteki ölü hücreleri nazikçe temizler ve cildin
              yenilenmesine yardımcı olur.
            </p>
            <p className="mt-3">
              Kolajen üretimini artırır ve ciltteki ince çizgileri ve
              kırışıklıkları azaltır.{" "}
            </p>
            <p className="mt-3">
              Daha genç ve canlı bir cilt görünümü sağlar.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Nemlendirme" />
            </h1>
            <img
              src={hydra3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">Hydrafacial, cilde yoğun nemlendirme sağlar.</p>
            <p className="mt-3">
              Cildin kurumasını önler ve yumuşak bir cilt hissi verir.{" "}
            </p>
            <p className="mt-3">
              Cilt bariyerini güçlendirir ve cilt tahrişini azaltır.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Akne Tedavisi" />
            </h1>
            <img
              src={hydra4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">Hydrafacial, akne tedavisinde de etkilidir.</p>
            <p className="mt-3">
              Akneye neden olan bakterileri yok eder ve iltihaplanmayı azaltır.{" "}
            </p>
            <p className="mt-3">Akne izlerinin görünümünü hafifletir. </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HydrafacialMen;
