import React from "react";
import "./bread.css";
import { NavLink } from "react-router-dom";

const BreadCrumb = ({ name, icon, image }) => {
  const backgroundImageStyle = {
    backgroundImage: `url(${image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div
      className="container-fluid bg-breadcrumb py-5"
      style={backgroundImageStyle}
    >
      <div className="container text-center py-5">
        <h3 className="text-white display-3 mb-4">
          {name} {icon}
        </h3>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item ">
            <NavLink className="bread-one" to="/">
              Ana Sayfa
            </NavLink>
          </li>
          <li className="breadcrumb-item ">
            <NavLink className="bread-one" to="/">
              Sayfalar
            </NavLink>
          </li>
          <li className="breadcrumb-item active text-warning bread-two">
            {name}
          </li>
        </ol>
      </div>
    </div>
  );
};

export default BreadCrumb;
