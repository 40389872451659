import React,{useEffect} from "react";
import car1 from "../Images/car1.jpg";
import kaliciOjeImage1 from "../Images/kaliciOjeImage1.jpg";
import kaliciOjeImage2 from "../Images/kaliciOjeImage2.jpg";
import kaliciOjeImage3 from "../Images/kaliciOjeImage3.jpg";
import kaliciOjeImage4 from "../Images/kaliciOjeImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const KaliciOje = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"Kalıcı Oje"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Uzun Süreli Mükemmellik" />
            </h1>
            <img
              src={kaliciOjeImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kalıcı oje, tırnaklarınızın 2-3 hafta boyunca kusursuz görünmesini
              sağlar.
            </p>
            <p className="mt-3">
              Klasik ojelerin aksine, çatlamaz, soyulmaz ve rengini kaybetmez.{" "}
            </p>
            <p className="mt-3">
              Her gün oje sürme ve çıkarma ihtiyacını ortadan kaldırır.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Güçlü ve Sağlıklı Tırnaklar" />
            </h1>
            <img
              src={kaliciOjeImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kalıcı oje, tırnaklarınızı güçlendirir ve kırılmalara karşı korur.
            </p>
            <p className="mt-3">
              Tırnaklarınızın daha sağlıklı uzamasına yardımcı olur.{" "}
            </p>
            <p className="mt-3">
              Tırnaklarınızın ince ve zayıf olmasından endişe ediyorsanız kalıcı
              oje ideal bir çözüm olabilir.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Zamandan Tasarruf" />
            </h1>
            <img
              src={kaliciOjeImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">Kalıcı oje, size zamandan tasarruf sağlar.</p>
            <p className="mt-3">
              Her gün oje sürmek ve çıkarmak için zaman harcamanıza gerek
              kalmaz.{" "}
            </p>
            <p className="mt-3">
              Bu sayede daha önemli işlere zaman ayırabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Farklı Tarzlara Uygun" />
            </h1>
            <img
              src={kaliciOjeImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kalıcı oje, klasik ve modern olmak üzere birçok farklı tarzda
              uygulanabilir.
            </p>
            <p className="mt-3">
              Desenler, simler ve aksesuarlar ile tarzınıza uygun bir kalıcı oje
              yaptırabilirsiniz.{" "}
            </p>
            <p className="mt-3">
            Her zevke ve tarza uygun bir kalıcı oje seçeneği mevcuttur.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KaliciOje;
