import "./nav.css";
import logo1 from "../Images/logo12.png";
import { IoIosArrowDown } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMailUnread } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import React, { useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdMale } from "react-icons/io";
import { IoMdFemale } from "react-icons/io";
import { FaCommentSms } from "react-icons/fa6";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import gunes from "../Images/gunes.svg";
const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMaleOpened, setIsMaleOpened] = useState(false);
  const [isFemaleOpened, setIsFemaleOpened] = useState(false);

  const handleMaleMouseEnter = () => {
    setIsMaleOpened(true);
  };

  const handleMaleMouseLeave = () => {
    setIsMaleOpened(false);
  };

  const handleFemaleMouseEnter = () => {
    setIsFemaleOpened(true);
  };

  const handleFemaleMouseLeave = () => {
    setIsFemaleOpened(false);
  };
  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };
  return (
    <div className="container-fluid sticky-top px-0 topbar-container">
      <div className="container-fluid topbar d-lg-block">
        <div className="container px-0">
          <div className="row align-items-center mx-0">
            <div className="col-lg-8 col-12">
              <div className="d-flex flex-wrap ">
                <NavLink
                  to="https://maps.app.goo.gl/2Bpb3TVyUbdkwDVh6"
                  target="_blank"
                  className="me-4 links d-none d-lg-block nav-hover"
                >
                  <MdLocationOn size={20} /> Adresi Göster
                </NavLink>
                <NavLink
                  className="me-4 links nav-hover"
                  onClick={() => (window.location.href = "tel:+905455931333")}
                  target="_blank"
                >
                  {" "}
                  <FaPhoneAlt /> 0545 593 13 33
                </NavLink>
                <NavLink to="contactForm" className="links nav-hover">
                  {" "}
                  <IoMailUnread /> gunesozcan880@gmail.com
                </NavLink>
              </div>
            </div>
            <div className="col-lg-4 col-12 top-navv">
              <div className="d-flex align-items-center justify-content-end">
                <NavLink
                  to="https://www.instagram.com/gunesozcan_guzellikatolyesi/"
                  target="_blank"
                  className="me-3 btn btn-square border p-1 nav-hover"
                >
                  <FaInstagram size={20} color="white" />
                </NavLink>
                <NavLink
                  to="https://api.whatsapp.com/send?phone=905455931333&text=Merhaba Güneş Özcan Beauty Center!"
                  target="_blank"
                  className="btn btn-square border p-1 me-3 nav-hover"
                >
                  <IoLogoWhatsapp size={20} color="white" />
                </NavLink>
                <NavLink
                  to="sms:05455931333;?&body=Merhaba Güneş Özcan Beauty Center!"
                  target="_blank"
                  className="btn btn-square border p-1 nav-hover"
                >
                  <FaCommentSms size={20} color="white" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid  shadow-sm p-3 mb-3 bg-white rounded navbar-container">
        <div className="container px-0">
          <nav className="navbar navbar-light navbar-expand-xl">
            <NavLink to="/" className="navbar-brand">
              <img src={gunes} alt="logo" className="img-logo" />
            </NavLink>
            <button
              className="navbar-toggler py-2 px-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              onClick={handleMobileMenuToggle}
            >
              <span className="text-primary">
                <GiHamburgerMenu />
              </span>
            </button>
            <div
              className={`collapse navbar-collapse  py-3 ${
                isMobileMenuOpen ? "show" : ""
              }`}
              id="navbarCollapse"
            >
              <div className="navbar-nav mx-auto border-top">
                <NavLink
                  to="/"
                  className="nav-item nav-link nav-hover"
                  onClick={handleLinkClick}
                >
                  Ana Sayfa
                </NavLink>
                <NavLink
                  to="/Hakkımızda"
                  className="nav-item nav-link active nav-hover"
                  onClick={handleLinkClick}
                >
                  Hakkımızda
                </NavLink>
                <div
                  className="nav-item dropdown"
                  onMouseEnter={handleFemaleMouseEnter}
                  onMouseLeave={handleFemaleMouseLeave}
                >
                  <NavLink
                    to="#"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <IoMdFemale /> Hizmetlerimiz{" "}
                    {isFemaleOpened ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "}
                  </NavLink>
                  <div
                    className={`dropdown-menu m-0 bg-secondary rounded-0 shadow-sm p-3 mb-5 bg-white rounded ${
                      isFemaleOpened ? "show" : ""
                    }`}
                  >
                    <NavLink
                      to="/LazerEpilasyon"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Lazer Epilasyon
                    </NavLink>
                    <NavLink
                      to="/CiltBakımı"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Cilt Bakımı
                    </NavLink>
                    <NavLink
                      to="/KalıcıMakyaj"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Kalıcı Makyaj
                    </NavLink>
                    <NavLink
                      to="/AltınOranKaşAlımı"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Altın Oran Kaş Alımı
                    </NavLink>
                    <NavLink
                      to="/Hydrafacial"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Hydrafacial
                    </NavLink>
                    <NavLink
                      to="/İpekKirpik"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      İpek Kirpik
                    </NavLink>
                    <NavLink
                      to="/Dermapen"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Dermapen
                    </NavLink>
                    <NavLink
                      to="/BölgeselZayıflama"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Bölgesel Zayıflama
                    </NavLink>
                    <NavLink
                      to="/G5Masajı"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      G9 Masajı
                    </NavLink>
                    <NavLink
                      to="/Manikür"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Manikür
                    </NavLink>
                    <NavLink
                      to="/KalıcıOje"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Kalıcı Oje
                    </NavLink>
                    <NavLink
                      to="/KirpikLifting"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Kirpik Lifting
                    </NavLink>
                  </div>
                </div>
                <div
                  className="nav-item dropdown"
                  onMouseEnter={handleMaleMouseEnter}
                  onMouseLeave={handleMaleMouseLeave}
                >
                  <NavLink
                    to="#"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <IoMdMale /> Hizmetlerimiz{" "}
                    {isMaleOpened ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "}
                  </NavLink>
                  <div
                    className={`dropdown-menu m-0 bg-secondary rounded-0 shadow-sm p-3 mb-5 bg-white rounded ${
                      isMaleOpened ? "show" : ""
                    }`}
                  >
                    <NavLink
                      to="/LazerEpilasyonn"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Lazer Epilasyon
                    </NavLink>
                    <NavLink
                      to="/CiltBakımın"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Cilt Bakımı
                    </NavLink>
                    <NavLink
                      to="/BölgeselZayıflaman"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Bölgesel Zayıflama
                    </NavLink>
                    <NavLink
                      to="/G5Masajın"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      G9 Masajı
                    </NavLink>
                    <NavLink
                      to="/Hydrafacialn"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Hydrafacial
                    </NavLink>
                    <NavLink
                      to="/Dermapenn"
                      className="dropdown-item"
                      onClick={handleLinkClick}
                    >
                      Dermapen
                    </NavLink>
                  </div>
                </div>
                <NavLink
                  to="/Galeri"
                  className="nav-item nav-link nav-hover"
                  onClick={handleLinkClick}
                >
                  Galeri
                </NavLink>
                <NavLink
                  to="/İletişim"
                  className="nav-item nav-link nav-hover"
                  onClick={handleLinkClick}
                >
                  İletişim
                </NavLink>
              </div>
              <div className="d-flex align-items-center flex-nowrap pt-xl-0 p-2">
                <NavLink
                  to="https://api.whatsapp.com/send?phone=905455931333&text=Merhaba Güneş Özcanbeauty Center, randevu ve bilgi almak istiyorum."
                  target="_blank"
                  className="nav-whats animate__animated  animate__shakeX nav-hover"
                  onClick={handleLinkClick}
                >
                  <MdOutlinePhoneInTalk size={20} /> Randevu Alın
                </NavLink>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default NavBar;
