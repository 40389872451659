import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import image from '../Images/instagramGalery.jpg'

const Videos = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const accessToken =
          "IGQWROLXdSNWVRc2M5a3VIeTN1cWR2T0tpV1pxOUpha2h0WXdPQ1NRTUxCYjVfbDFQZA1FDSWlhdW1zTU1OMElweTM4OFNmLUlEUHVKWmdaRkRCOFVRTk9MaU4weG9Ic1ktRnVUakRzTExzQnVXQnZA2UmRjQWFVcWMZD";

        const response = await axios.get(
          "https://graph.instagram.com/me/media",
          {
            params: {
              fields: "id,media_type,media_url",
              access_token: accessToken,
            },
          }
        );
        const filteredVideos = response.data.data.filter(
          (media) => media.media_type === "VIDEO"
        );
        setVideos(filteredVideos);
      } catch (error) {
        console.error("Error fetching Instagram videos:", error);
      }
    };

    fetchVideos();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handlePlay = (index) => {
    const newVideos = [...videos];
    newVideos[index].playing = true;
    setVideos(newVideos);
  };

  const handlePause = (index) => {
    const newVideos = [...videos];
    newVideos[index].playing = false;
    setVideos(newVideos);
  };
  const backgroundImageStyle = {
    backgroundImage: `url(${image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height:"300px"
  };
  return (
    <div>
      <div
      className="container-fluid bg-breadcrumb py-5"
      style={backgroundImageStyle}
    ></div>
      <div className="container videos-container">
        {videos.length === 0 && (
          <div className="alert alert-warning" role="alert">
            Henüz hiç video yüklenmedi.
          </div>
        )}
        <div className="row">
          {videos.map((video, index) => (
            <div key={video.id} className="col-lg-3 col-md-4 mb-4">
              {video.media_type === "VIDEO" && (
                <div className="position-relative  overflow-hidden">
                  <ReactPlayer
                    url={video.media_url}
                    playing={video.playing}
                    controls={video.playing}
                    width="100%"
                    height="auto"
                    onPlay={() => handlePlay(index)}
                    onPause={() => handlePause(index)}
                    className="rounded"
                  />
                  {!video.playing && (
                    <div
                      className="play-button position-absolute top-50 start-50 translate-middle"
                      onClick={() => handlePlay(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="128"
                        height="128"
                        viewBox="0 0 128 128"
                      >
                        <circle
                          cx="64"
                          cy="64"
                          r="64"
                          fill="#000"
                          fillOpacity="0"
                        />
                        <path fill="#FFF" d="M40 36 L88 64 L40 92 Z" />
                      </svg>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Videos;

