import React , {useEffect} from "react";
import lazer1 from "../Images/lazer1.jpg";
import lazer2 from "../Images/lazer5.jpg";
import lazer3 from "../Images/lazer3.jpg";
import lazer4 from "../Images/lazer4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "../Servicess/lazer.css";
import AnimatedText from "../Servicess/AnimatedText";
import { IoMdMale } from "react-icons/io";
import breadmale from "../Images/breadmale.jpg";

const LazerMen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb
        name={"Lazer Epilasyon"}
        icon={<IoMdMale />}
        image={breadmale}
      />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Pürüzsüz ve Temiz Cilt" />
            </h1>
            <img
              src={lazer1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Lazer epilasyon, istenmeyen tüylerden kalıcı olarak kurtulmak için
              en etkili yöntemdir.
            </p>
            <p className="mt-3">
              Sırt, göğüs, karın, kol, bacak gibi tüm vücut bölgelerinde
              uygulanabilir.{" "}
            </p>
            <p className="mt-3">Pürüzsüz ve temiz bir cilt görünümü sağlar. </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Rahatlama ve Pratiklik" />
            </h1>
            <img
              src={lazer2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Lazer epilasyon, ağda ve jilet gibi yöntemlere kıyasla daha az
              ağrılı ve tahriş edicidir.
            </p>
            <p className="mt-3">
              Kısa seanslar halinde uygulanır ve günlük hayatınızı aksatmaz.{" "}
            </p>
            <p className="mt-3">
              Tıraş olma ve kıl dönmesi gibi problemlerden kurtulmanıza yardımcı
              olur.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Artan Özgüven" />
            </h1>
            <img
              src={lazer3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Lazer epilasyon, istenmeyen tüylerden kurtulmak ve daha estetik
              bir görünüme kavuşmak için ideal bir yöntemdir.
            </p>
            <p className="mt-3">
              Kendinizi daha özgüvenli ve çekici hissetmenizi sağlar.{" "}
            </p>
            <p className="mt-3">
              Özellikle yaz aylarında rahatlıkla tişört ve şort giymenizi
              sağlar.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Ekonomik Avantaj" />
            </h1>
            <img
              src={lazer4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Lazer epilasyon, uzun vadede düşünüldüğünde oldukça ekonomik bir
              yöntemdir.
            </p>
            <p className="mt-3">
              Ağda, jilet ve diğer tüy alma yöntemlerine sürekli olarak para
              harcamanıza gerek kalmaz.{" "}
            </p>
            <p className="mt-3">
              Zamandan ve paradan tasarruf etmenizi sağlar.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LazerMen;
