import React from "react";
import { NavLink } from "react-router-dom";
import "./sticky.css";
import whatsapp from "../Images/whats.png";
import insta from "../Images/insta.png";
import SMS from "../Images/gpsnew.png";
import phone from "../Images/phone.png";
function StickyFooter() {
  return (
    <footer className="sticky-footer">
      <div className="social-icons">
        <NavLink
          to="https://www.instagram.com/gunesozcan_guzellikatolyesi/"
          target="_blank"
          className="icon-link"
        >
          <div className="icon-circle">
            <img src={insta} alt="insta-image" width={"45px"} height={"45px"} />
          </div>
          <span className="icon-name">Instagram</span>
        </NavLink>
        <NavLink
          to="https://api.whatsapp.com/send?phone=905455931333&text=Merhaba  Güneş Özcan Beauty Center"
          className="icon-link"
          target="_blank"
        >
          <div className="icon-circle">
            <img src={whatsapp} alt="whatsapp" width={"50px"} height={"50px"} />
          </div>
          <span className="icon-name">WhatsApp</span>
        </NavLink>
        <NavLink
          to="https://maps.app.goo.gl/2Bpb3TVyUbdkwDVh6"
          target="_blank"
          className="icon-link"
        >
          <div className="icon-circle">
            <img src={SMS} alt="mail-image" width={"50px"} height={"50px"} />
          </div>
          <span className="icon-name">Adres</span>
        </NavLink>

        <NavLink to="tel:+905455931333" className="icon-link" target="_blank">
          <div className="icon-circle">
            <img src={phone} alt="phone-image" width={"70px"} height={"50px"} />
          </div>
          <span className="icon-name">Telefon</span>
        </NavLink>
      </div>
    </footer>
  );
}

export default StickyFooter;
