import React,{useEffect} from "react";
import car1 from "../Images/car1.jpg";
import ciltImage1 from "../Images/ciltImage1.jpg";
import ciltImage2 from "../Images/ciltImage2.jpg";
import ciltImage3 from "../Images/ciltImage3.jpg";
import ciltImage4 from "../Images/ciltImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Cilt = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"Cilt Bakımı"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Işıltılı Cilde Giden Yol" />
            </h1>
            <img
              src={ciltImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Cilt bakımı, ışıl ışıl parlayan ve sağlıklı bir cilde sahip
              olmanın en keyifli yoludur.
            </p>
            <p className="mt-3">
              Cildinize ihtiyacı olan besinleri vererek, onu dış etkenlerden
              korur ve en güzel haliyle parlamasını sağlarsınız.{" "}
            </p>
            <p className="mt-3">
              Cilt bakımı sadece güzellik için değil, aynı zamanda cildinizin
              sağlığı için de önemlidir. Düzenli cilt bakımı ile cildinizin
              yaşlanma belirtilerini geciktirebilir, daha canlı ve genç
              görünmesini sağlayabilirsiniz.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kendinize Yapacağınız En Güzel Yatırım" />
            </h1>
            <img
              src={ciltImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Cilt bakımı, kendinize yapabileceğiniz en güzel yatırımlardan
              biridir.
            </p>
            <p className="mt-3">
              Cildinize özen göstererek hem daha güzel görünür hem de kendinizi
              daha iyi hissedersiniz{" "}
            </p>
            <p className="mt-3">
              Cilt bakımı stresten uzaklaşmanızı ve rahatlamanızı sağlar.
              Kendinize zaman ayırarak cildinize özen göstermek, ruh halinize de
              olumlu yansır.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Zamana Meydan Okuyun" />
            </h1>
            <img
              src={ciltImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Cilt bakımı, yaşlanma belirtilerini geciktirmenin ve cildinizin
              daha uzun süre genç görünmesini sağlamanın en etkili yollarından
              biridir.
            </p>
            <p className="mt-3">
              Düzenli cilt bakımı ile cildinizin elastikiyetini artırabilir,
              kırışıklık ve ince çizgilerin oluşumunu engelleyebilirsiniz.{" "}
            </p>
            <p className="mt-3">
              Cildinizin daha sıkı ve pürüzsüz görünmesini sağlayabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kusursuz Güzelliğin Sırrı" />
            </h1>
            <img
              src={ciltImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Cilt bakımı, kusursuz bir güzelliğe sahip olmanın anahtarıdır.{" "}
            </p>
            <p className="mt-3">
              Cildinizin ihtiyacına uygun ürünler kullanarak, lekelerden,
              aknelerden ve siyah noktalardan kurtulabilirsiniz.{" "}
            </p>
            <p className="mt-3">
              Cildinizin daha aydınlık ve canlı görünmesini sağlayabilirsiniz.
              Cilt bakımı ile makyajsız da özgüvenli hissedebilirsiniz{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cilt;
