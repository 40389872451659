import React , {useEffect} from "react"
import Carousel from "./Carousel"
import Services from "./Services"
import Contact from "./Contact"

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div>
            <Carousel />
            <Services />
            <Contact />

        </div>
    )
}
export default Home