import React, {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import manikurImage1 from "../Images/manikurImage1.jpg";
import manikurImage2 from "../Images/manikurImage2.jpg";
import manikurImage3 from "../Images/manikurImage3.jpg";
import manikurImage4 from "../Images/manikurImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Manikur = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"Manikür"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kusursuz Eller" />
            </h1>
            <img
              src={manikurImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Manikür, ellerinizin kusursuz görünmesini sağlayan bir bakım
              yöntemidir
            </p>
            <p className="mt-3">
              Tırnaklarınızı şekillendirerek, temizleyerek ve cilalayarak daha
              güzel ve bakımlı görünmelerine yardımcı olur.{" "}
            </p>
            <p className="mt-3">
              Tırnaklarınızı şekillendirerek, temizleyerek ve cilalayarak daha
              güzel ve bakımlı görünmelerine yardımcı olur.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Bakımlı Eller, Bakımlı Bir Görünüm" />
            </h1>
            <img
              src={manikurImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Elleriniz, ilk bakışta dikkat çeken ve sizi temsil eden önemli bir
              bölgenizdir.
            </p>
            <p className="mt-3">
              Bakımlı ve güzel eller, size daha özgüvenli ve çekici bir görünüm
              kazandırır.{" "}
            </p>
            <p className="mt-3">
              Ellerinizin bakımlı olması, genel görünümünüzü de tamamlar ve daha
              şık görünmenizi sağlar.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Rahatlamanın Keyfini Çıkarın" />
            </h1>
            <img
              src={manikurImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Manikür, sadece tırnak bakımı değil, aynı zamanda bir rahatlama
              deneyimidir.
            </p>
            <p className="mt-3">
              Manikür yaptırırken ellerinize masaj yapılır ve stresten
              uzaklaşmanız sağlanır.{" "}
            </p>
            <p className="mt-3">
              Manikür sırasında ellerinizin yumuşamasını ve rahatlamasını
              hissedebilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Farklı Tarzlara Uygun" />
            </h1>
            <img
              src={manikurImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Manikür, klasik ve modern olmak üzere birçok farklı tarzda
              uygulanabilir.
            </p>
            <p className="mt-3">
              Renkli ojeler, desenler ve aksesuarlar ile tarzınıza uygun bir
              manikür yaptırabilirsiniz.{" "}
            </p>
            <p className="mt-3">
              Manikür, sadece tırnaklarınız için değil, ellerinizin cildinin de
              sağlıklı kalmasına yardımcı olur.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manikur;
