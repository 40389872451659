import React, {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import liftingImage1 from "../Images/liftingImage1.jpg";
import liftingImage2 from "../Images/liftingImage2.jpg";
import liftingImage3 from "../Images/liftingImage3.jpg";
import liftingImage4 from "../Images/liftingImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Kirpik = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"Kirpik Lifting"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Daha Uzun ve Hacimli Kirpikler" />
            </h1>
            <img
              src={liftingImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kirpik liftingi, kirpiklerinizi doğal olarak daha uzun ve hacimli
              gösterir.
            </p>
            <p className="mt-3">
              Maskara kullanmadan bile gözlerinizin daha belirgin ve etkileyici
              görünmesini sağlar.{" "}
            </p>
            <p className="mt-3">
              Kirpiklerinizin doğal güzelliğini ortaya çıkarır.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Pratik ve Zamandan Tasarruf" />
            </h1>
            <img
              src={liftingImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kirpik liftingi, her gün maskara sürme ihtiyacını ortadan
              kaldırır.
            </p>
            <p className="mt-3">
              Sabah uyandığınızda kirpiklerinizin uzun ve hacimli görünmesini
              sağlar.{" "}
            </p>
            <p className="mt-3">
              Makyaj yaparken zamandan tasarruf etmenizi sağlar.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Daha Etkileyici Bakışlar" />
            </h1>
            <img
              src={liftingImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kirpik liftingi, bakışlarınızın daha derin ve etkileyici
              görünmesini sağlar.
            </p>
            <p className="mt-3">
              Gözlerinizin ön plana çıkmasını ve daha çekici görünmenizi sağlar.{" "}
            </p>
            <p className="mt-3">Kendinize olan güveninizi artırır. </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Uzun Süreli Kalıcılık" />
            </h1>
            <img
              src={liftingImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kirpik liftinginin etkisi 6-8 haftaya kadar kalabilir.
            </p>
            <p className="mt-3">
              Bu sayede uzun süre boyunca kirpiklerinizin güzelliğinin keyfini
              çıkarabilirsiniz.{" "}
            </p>
            <p className="mt-3">
              Düzenli olarak kirpik liftingi yaptırarak kirpiklerinizin her
              zaman bakımlı görünmesini sağlayabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kirpik;
