import React , {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import bolgeselImage1 from "../Images/bolgeselImage1.jpg";
import bolgeselImage2 from "../Images/bolgeselImage2.jpg";
import bolgeselImage3 from "../Images/bolgeselImage3.jpg";
import bolgeselImage4 from "../Images/bolgeselImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Bolgesel = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb
        image={car1}
        name={"Bölgesel Zayıflama"}
        icon={<IoMdFemale />}
      />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Hayalinizdeki Vücuda Kavuşun" />
            </h1>
            <img
              src={bolgeselImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Bölgesel zayıflama, inatçı yağlardan kurtularak hayalinizdeki
              vücuda kavuşmanızı sağlayan etkili bir yöntemdir.
            </p>
            <p className="mt-3">
              Diyet ve egzersizle ulaşamadığınız bölgesel yağlanmalara karşı
              çözüm sunar.{" "}
            </p>
            <p className="mt-3">
              Karın, basen, bacak ve kol gibi yağlanma eğilimi gösteren
              bölgelerdeki yağları hedef alarak incelmenize yardımcı olur.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kendinize Güvenin" />
            </h1>
            <img
              src={bolgeselImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Bölgesel zayıflama, özgüveninizi artırmanın en güzel yollarından
              biridir.
            </p>
            <p className="mt-3">
              Daha fit ve orantılı bir vücuda sahip olmak, kendinizi daha iyi
              hissetmenizi sağlar.{" "}
            </p>
            <p className="mt-3">
              Her ortamda özgüvenle kıyafetlerinizi giyebilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kıyafetleriniz Daha Güzel Görünsün" />
            </h1>
            <img
              src={bolgeselImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Bölgesel zayıflama, kıyafetlerinizin daha güzel görünmesini
              sağlar.
            </p>
            <p className="mt-3">
              Vücudunuzun hatlarını belirginleştirerek daha şık ve çekici
              görünmenize yardımcı olur.{" "}
            </p>
            <p className="mt-3">
              Bölgesel zayıflama yöntemleri, cerrahi müdahale gerektirmez.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Hızlı ve Kalıcı Sonuçlar" />
            </h1>
            <img
              src={bolgeselImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Bölgesel zayıflama yöntemleri, hızlı ve kalıcı sonuçlar sunar.
            </p>
            <p className="mt-3">
              Kısa sürede incelmenize ve fit bir görünüme kavuşmanıza yardımcı
              olur.{" "}
            </p>
            <p className="mt-3">
              Bölgesel zayıflama ile hayalinizdeki vücuda güvenli ve sağlıklı
              bir şekilde kavuşabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bolgesel;
