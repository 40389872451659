import React , {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import hydrafacialImage1 from "../Images/hydrafacialImage1.jpg";
import hydrafacialImage2 from "../Images/hydrafacialImage2.jpg";
import hydrafacialImage3 from "../Images/hydrafacialImage3.jpg";
import hydrafacialImage4 from "../Images/hydrafacialImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Hydrafacial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"Hydrafacial"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Pürüzsüzlüğe Giden Yol" />
            </h1>
            <img
              src={hydrafacialImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Hydrafacial, cildinizin ihtiyacı olan tüm bakımı tek seansta sunan
              yenilikçi bir cilt bakımı yöntemidir.
            </p>
            <p className="mt-3">
              Cildinizi derinlemesine temizler, peeling yapar, nemlendirir ve
              besler.{" "}
            </p>
            <p className="mt-3">
              Hydrafacial ile cildinizin daha pürüzsüz, aydınlık ve canlı
              görünmesini sağlayabilirsiniz.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Cildinizi Yenileyin" />
            </h1>
            <img
              src={hydrafacialImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Hydrafacial, ciltteki siyah noktaları, akneleri ve lekeleri yok
              etmeye yardımcı olur.
            </p>
            <p className="mt-3">
              Ciltteki gözenekleri sıkılaştırır ve yaşlanma belirtilerini
              geciktirir.{" "}
            </p>
            <p className="mt-3">
              Hydrafacial ile cildinizin daha genç ve sağlıklı görünmesini
              sağlayabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Rahatlatıcı Bir Deneyim" />
            </h1>
            <img
              src={hydrafacialImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Hydrafacial, acısız ve konforlu bir işlemdir.
            </p>
            <p className="mt-3">
              Cildinize herhangi bir zarar vermeden derinlemesine temizlik ve
              bakım sağlar.{" "}
            </p>
            <p className="mt-3">
              Hydrafacial ile cildinizin tazelendiğini ve canlandığını
              hissedeceksiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Her Cilt Tipine Uygun" />
            </h1>
            <img
              src={hydrafacialImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Hydrafacial, her cilt tipine uygulanabilen bir cilt bakımı
              yöntemidir.
            </p>
            <p className="mt-3">
              Kuru, yağlı, karma veya hassas cilt tiplerinde güvenle
              kullanılabilir.{" "}
            </p>
            <p className="mt-3">
              Hydrafacial ile cildinizin ihtiyacı olan özel bakımı
              alabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hydrafacial;
