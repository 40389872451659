import React, {useEffect} from "react";
import masaj1 from "../Images/masaj1.png";
import masaj2 from "../Images/masaj2.png";
import BreadCrumb from "../Home/BreadCrumb";
import "../Servicess/lazer.css";
import AnimatedText from "../Servicess/AnimatedText";
import { IoMdMale } from "react-icons/io";
import breadmale from "../Images/breadmale.jpg";
import g5masaji from '../Images/g5masaji.jpg'
const MasajiMen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb name={"G9 Masajı"} icon={<IoMdMale />} image={breadmale} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kas Gerginliğini Azaltır" />
            </h1>
            <img
              src={masaj1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              G9 Masajı, yoğun egzersiz sonrası oluşan kas gerginliğini ve
              ağrıları azaltmaya yardımcı olur.
            </p>
            <p className="mt-3">Kasların daha hızlı toparlanmasını sağlar. </p>
            <p className="mt-3">Spor performansını artırır. </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text=" Dolaşımı Artırır" />
            </h1>
            <img
              src={g5masaji}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              G9 Masajı, kan dolaşımını hızlandırır ve hücrelere daha fazla
              oksijen taşınmasını sağlar.
            </p>
            <p className="mt-3">Bu sayede yorgunluk ve kas ağrıları azalır. </p>
            <p className="mt-3">
              Vücuttaki toksinlerin atılmasına yardımcı olur.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasajiMen;
