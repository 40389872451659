import React from "react";
import { FaRegCopyright } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./bottomfooter.css";
const BottomFooter = () => {
  return (
    <div className="container-fluid copyright py-4">
      <div className="container">
        <div className="row g-4 align-items-center">
          <div className="col-md-4 text-center text-md-start mb-md-0 text-jusitfy">
            <span className="text-light">
              <span href="/">
                <FaRegCopyright /> Güneş Özcan{" "}
              </span>
              , All right reserved.
            </span>
          </div>
          <div className="col-md-4">
            <div className="d-flex justify-content-center">
              <NavLink
                to=""
                className="btn btn-dark btn-light-outline-0 btn-sm-square rounded me-2"
              >
                <FaInstagram size={25} color="#9D8221" />
              </NavLink>
            </div>
          </div>
          <div className="col-md-4 text-center text-md-end text-white developed">
          Bu web sitesi{" "}
            <NavLink
              className="border-bottom fuat nav-hover"
              to="https://api.whatsapp.com/send?phone=905050945090&text=Merhaba internet sitesi yaptırmak istiyorum!"
              target="_blank"
            >
              FUAT YAGIZ AYDIN {" "}
            </NavLink>
            tarafından hazırlanmıştır
          </div>
        </div>
      </div>
    </div>
  );
};
export default BottomFooter;
