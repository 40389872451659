import React , {useEffect} from "react";
import car1 from "../Images/car1.jpg";
import makyajImage1 from "../Images/makyajImage1.jpg";
import makyajImage2 from "../Images/makyajImage2.jpg";
import makyajImage3 from "../Images/makyajImage3.jpg";
import makyajImage4 from "../Images/makyajImage4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "./lazer.css";
import AnimatedText from "./AnimatedText";
import { IoMdFemale } from "react-icons/io";

const Kalici = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb image={car1} name={"Kalıcı Makyaj"} icon={<IoMdFemale />} />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Mükemmelliğe Uzanan Yol" />
            </h1>
            <img
              src={makyajImage1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kalıcı makyaj, her zaman kusursuz ve bakımlı görünmenin en pratik
              yoludur.
            </p>
            <p className="mt-3">
              Sabahları makyaj yapma derdinden kurtularak zamandan tasarruf
              edersiniz.{" "}
            </p>
            <p className="mt-3">
              Kalıcı makyaj ile her zaman doğal ve güzel görünürsünüz. Suya,
              tere ve yağmura karşı dayanıklıdır, bu da onu her mevsim için
              ideal bir seçenek haline getirir.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Güzelliğinizi Vurgulayın" />
            </h1>
            <img
              src={makyajImage2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kalıcı makyaj ile kaşlarınızı, eyelinerlarınızı ve dudaklarınızı
              istediğiniz gibi şekillendirebilirsiniz.
            </p>
            <p className="mt-3">
              Gözlerinizi daha iri, kaşlarınızı daha dolgun, dudaklarınızı ise
              daha canlı ve dolgun gösterebilirsiniz.{" "}
            </p>
            <p className="mt-3">
              Kalıcı makyaj ile yüz hatlarınızı belirginleştirerek daha çekici
              bir görünüme kavuşabilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Kendinize Güvenin" />
            </h1>
            <img
              src={makyajImage3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kalıcı makyaj, özgüveninizi artırmanın en etkili yollarından
              biridir.
            </p>
            <p className="mt-3">
              Her zaman bakımlı görünmek, kendinizi daha iyi hissetmenizi
              sağlar.{" "}
            </p>
            <p className="mt-3">
              Kalıcı makyaj ile her ortamda rahat ve özgüvenli
              hissedebilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Zamansız Güzellik" />
            </h1>
            <img
              src={makyajImage4}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Kalıcı makyaj, yıllar boyunca güzelliğinizi korumanızı sağlayan
              bir yöntemdir.
            </p>
            <p className="mt-3">
              Makyajınızın akması veya dağılması derdinden kurtularak her zaman
              kusursuz görünürsünüz.{" "}
            </p>
            <p className="mt-3">
              Kalıcı makyaj ile zamandan ve paradan tasarruf ederek
              güzelliğinizi kalıcı hale getirebilirsiniz.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kalici;
