import React , {useEffect} from "react";
import bolgesel from "../Images/bolgeselmale1.jpg";
import bolgesel1 from "../Images/bolgesel1.jpeg";
import bolgesel2 from "../Images/bolgesel3.jpg";
import bolgesel3 from "../Images/bolgesel4.jpg";
import BreadCrumb from "../Home/BreadCrumb";
import "../Servicess/lazer.css";
import AnimatedText from "../Servicess/AnimatedText";
import { IoMdMale } from "react-icons/io";
import breadmale from "../Images/breadmale.jpg";
const BolgeselMen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BreadCrumb
        name={"Bölgesel Zayıflama"}
        icon={<IoMdMale />}
        image={breadmale}
      />
      <div className="container lazer-container">
        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="İstenmeyen Yağlardan Kurtulma" />
            </h1>
            <img
              src={bolgesel}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Bölgesel zayıflama, erkeklerin inatçı yağlardan kurtulmasına
              yardımcı olur.
            </p>
            <p className="mt-3">
              Karın, bel, göğüs ve love handle gibi yağlanma eğilimi gösteren
              bölgelerdeki yağları hedef alır.{" "}
            </p>
            <p className="mt-3">
              Daha fit ve atletik bir vücuda kavuşmanızı sağlar.{" "}
            </p>
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-up"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Vücut Konturunun Düzelmesi" />
            </h1>
            <img
              src={bolgesel1}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Bölgesel zayıflama, vücut konturunun düzelmesine yardımcı olur.
            </p>
            <p className="mt-3">
              Daha orantılı ve simetrik bir vücut görünümü sağlar.{" "}
            </p>
            <p className="mt-3">Kendinize olan güveninizi artırır. </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-down"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Daha Enerjik ve Sağlıklı Yaşam" />
            </h1>
            <img
              src={bolgesel2}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Bölgesel zayıflama, daha enerjik ve sağlıklı bir yaşam tarzı için
              önemlidir.
            </p>
            <p className="mt-3">
              Kalp hastalıkları, diyabet ve eklem problemleri gibi riskleri
              azaltır.{" "}
            </p>
            <p className="mt-3">
              Fazla kilolardan kurtulmak, genel sağlık durumunuzu iyileştirir.{" "}
            </p>{" "}
          </div>
        </div>

        <div
          className="row mt-5 text-center align-items-center"
          data-aos="zoom-in-left"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="display-4 mb-4 head">
              <AnimatedText text="Daha İyi Giyinin" />
            </h1>
            <img
              src={bolgesel3}
              className="img-fluid custom-image"
              alt="Lazer Image"
            />
            <p className="mt-3">
              Bölgesel zayıflama, daha iyi giysilerin size daha güzel
              görünmesini sağlar.
            </p>
            <p className="mt-3">
              Kıyafetlerinizin daha rahat ve şık durmasını sağlar.{" "}
            </p>
            <p className="mt-3">
              Kendinizi daha iyi hissetmenizi ve özgüveninizi artırır.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BolgeselMen;
